/* Reset.css or Normalize.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  line-height: 1.6;
  overflow-y: hidden !important;
}

/* Additional reset styles as needed */
/* Remove default styles for list items and anchor links */
li,
a {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

.swiper-container.mainslider {
  padding: 0;
}

.owl-prev,
.owl-next {
  font-size: 24px;
  color: #333;
}

.owl-prev:hover,
.owl-next:hover {
  color: #555;
}

.flat-features .box-content {
  position: relative;
}

.home_services.features-box.headline {
  padding-top: 50px !important;
}

.owl-nav {
  display: none;
}

.home_services.flat-features .carousel {
  padding: 52px 0 0px;
}

.home_banner_slider .owl-dots .owl-dot {
  border: 1px solid #777 !important;
  margin: 0 5px;
  border-radius: 50%;
  height: 25px;
}

.flat-features .carousel {
  padding: 60px 0 0px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #c30500 !important;
}

.flat-team .team-content-box .team-post img {
  width: 100%;
}

.hed_mail_id span {
  padding: 0 15px !important;
}

.caseStudyCard {
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  color: currentColor;
  text-decoration: none;
  overflow: hidden;
  transition-property: color;
  transition-delay: 0.15s;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.caseStudyCard.hover {
  color: white;
  transition-delay: 0;
}

.caseStudyCard,
.card__image,
.card__image::after,
.card__author,
.card__body,
.card__foot,
.card__border {
  transition-duration: 0.4s;
  transition-timing-function: cubic-bezier(0.51, 0.92, 0.24, 1);
}

.card__head {
  position: relative;
  padding-top: 70%;
}

.card__author {
  position: absolute;
  padding: 2em;
  left: 0;
  bottom: 0;
  color: white;
  transition-property: transform;
  transition-delay: 0.15s;
}

.caseStudyCard.hover .card__author {
  transition-delay: 0;
}

.card__image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-image: url("https://images.unsplash.com/photo-1553787762-b5f5721f3270?ixlib=rb-1.2.1&auto=format&fit=crop&w=2100&q=80");
  transform-origin: top center;
  transition-property: transform;
  transition-delay: 0.15s;
}

.card__image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  background: linear-gradient(30deg, rgba(26, 42, 99, 0.85), rgba(26, 42, 99, 0.5));
  transition-property: opacity;
  transition-delay: 0.15s;
}

.caseStudyCard.hover .card__image {
  transition-delay: 0;
}

.caseStudyCard.hover .card__image::after {
  opacity: 1;
  transition-delay: 0;
}

.card__body {
  position: relative;
  padding: 2em;
  transition-property: transform;
  transition-delay: 0.15s;
}

.caseStudyCard.hover .card__body {
  transition-delay: 0;
}

.card__headline {
  font-weight: 400;
  margin: 0 0 0.3em;
  font-size: 18px;
}

.card__text {
  line-height: 1.5;
  margin: 0;
  opacity: 0.8;
}

.card__foot {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 2em 2em;
  opacity: 0;
  transition-property: opacity;
}

.caseStudyCard.hover .card__foot {
  opacity: 1;
  transition-delay: 0.15s;
}

.card__link {
  color: currentColor;
  text-decoration: none;
  border-bottom: 2px solid #b5272d;
}

.card__border {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 6px;
  background: #b5272d;
  transform: scaleY(0);
  transition-property: transform;
}

.caseStudyCard.hover .card__border {
  transform: none;
  transition-delay: 0.15s;
}

.author_case {
  display: flex;
  align-items: center;
}

.author__image {
  flex-shrink: 0;
  margin-right: 0.6rem;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
}

.author__content {
  display: grid;
  grid-gap: 0.4em;
  font-size: 0.9em;
}

.author__header {
  margin: 0;
  font-weight: 600;
  font-size: 13px;
}

.author__subheader {
  margin: 0;
  opacity: 0.8;
  font-size: 12px;
}

#gallery {
  position: relative;
  left: calc(-1 * var(--adjust-size));
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  max-width: 100vw;
  -webkit-perspective: 0;
  perspective: 0;
}

#gallery figure:nth-child(7n) {
  --duration: 1s;
  --pin-color: crimson;
}

#gallery figure:nth-child(7n + 1) {
  --duration: 1.8s;
  --pin-color: hotpink;
}

#gallery figure:nth-child(7n + 2) {
  --duration: 1.3s;
  --pin-color: magenta;
}

#gallery figure:nth-child(7n + 3) {
  --duration: 1.5s;
  --pin-color: orangered;
}

#gallery figure:nth-child(7n + 4) {
  --duration: 1.1s;
  --pin-color: darkorchid;
}

#gallery figure:nth-child(7n + 5) {
  --duration: 1.6s;
  --pin-color: deeppink;
}

#gallery figure:nth-child(7n + 6) {
  --duration: 1.2s;
  --pin-color: mediumvioletred;
}

#gallery figure:nth-child(3n) {
  --angle: 3deg;
}

#gallery figure:nth-child(3n + 1) {
  --angle: -3.3deg;
}

#gallery figure:nth-child(3n + 2) {
  --angle: 2.4deg;
}

#gallery figure:nth-child(odd) {
  --direction: alternate;
}

#gallery figure:nth-child(even) {
  --direction: alternate-reverse;
}

#gallery figure {
  --angle: 3deg;
  --count: 5;
  --duration: 1s;
  --delay: calc(-0.5 * var(--duration));
  --direction: alternate;
  --pin-color: red;

  position: relative;
  display: inline-block;
  margin: var(--adjust-size);
  padding: 0.5rem;
  border-radius: 5px;
  box-shadow: 0 7px 8px rgba(0, 0, 0, 0.4);
  width: 100%;
  height: auto;
  text-align: center;
  background-color: ghostwhite;
  background-image: url("https://images.unsplash.com/photo-1629968417850-3505f5180761?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wzMjM4NDZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE2OTMzMjQ3ODJ8&ixlib=rb-4.0.3&q=80&w=500");
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;

  transform-origin: center 0.22rem;
  will-change: transform;
  break-inside: avoid;
  overflow: hidden;
  outline: 1px solid transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

#gallery.active figure {
  animation-duration: var(--duration), 1.5s;
  animation-delay: var(--delay),
    calc(var(--delay) + var(--duration) * var(--count));
  animation-timing-function: ease-in-out;
  animation-iteration-count: var(--count), 1;
  animation-direction: var(--direction), normal;
  animation-fill-mode: both;
  animation-name: swing, swingEnd;
}

#gallery figure:after {
  position: absolute;
  top: 0.22rem;
  left: 50%;
  width: 0.7rem;
  height: 0.7rem;
  content: "";
  background: var(--pin-color);
  border-radius: 50%;
  box-shadow: -0.1rem -0.1rem 0.3rem 0.02rem rgba(0, 0, 0, 0.5) inset;
  filter: drop-shadow(0.3rem 0.15rem 0.2rem rgba(0, 0, 0, 0.5));
  transform: translateZ(0);
  z-index: 2;
}

figure img {
  aspect-ratio: 1 /1;
  width: 100%;
  object-fit: cover;
  display: block;
  border-radius: 5px;
  margin-bottom: 10px;
  z-index: 1;
}

figure figcaption {
  font-size: 14px;
  font-weight: 400;
  z-index: 1;
}

figure h2 {
  color: crimson;
  font-size: 22px;
}

figure p {
  font-size: 17px;
}

figure small {
  font-size: 12px;
}

figure>div {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes swing {
  0% {
    transform: rotate3d(0, 0, 1, calc(-1 * var(--angle)));
  }

  100% {
    transform: rotate3d(0, 0, 1, var(--angle));
  }
}

@keyframes swingEnd {
  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

#info {
  position: relative;
  text-align: center;
  z-index: 1;
}

#info a {
  font-size: 1.1rem;
}

@media (orientation: landscape) {
  #gallery {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}

@media (min-width: 800px) {
  #gallery {
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  }
}

.abt_us_img {
  min-height: 500px;
  max-height: 500px;
  object-fit: cover;
}

.what_we_do_sec .text-wrap p {
  font-size: 15px;
  min-height: 145px;
}

.loader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
  height: 100vh;
}

.loader .ring {
  position: absolute;
  height: 150px;
  width: 150px;
  border: 0px solid #ffffff;
  border-radius: 50%;
}

.loader .ring:nth-child(1) {
  border-bottom-width: 10px;
  border-color: #fff;
  animation: rotate1 1.5s linear infinite;
}

.loader .ring:nth-child(2) {
  border-right-width: 10px;
  border-color: #fff;
  animation: rotate2 1.5s linear infinite;
}

.loader .ring:nth-child(3) {
  border-top-width: 10px;
  border-color: #fff;
  animation: rotate3 1.5s linear infinite;
}


@keyframes rotate1 {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg)
  }

  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg)
  }
}

@keyframes rotate2 {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg)
  }

  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg)
  }
}

@keyframes rotate3 {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg)
  }

  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg)
  }
}

.overlay_loader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #00000099;
  z-index: 99999999;
}

.loader_section {
  position: fixed;
  z-index: 99999;
  height: 100vh;
  width: 100%;
}

.wrapper-career {
  display: block;
  height: 190px;
  position: relative;
  margin-bottom: 2rem;
  text-align: center;
}


.orange-border {
  background-color: #ff00003b;
  background-image: linear-gradient(130deg, #ff00006b, #ffffff);
  position: absolute;
}

.orange-top {
  width: 100px;
  height: 5px;
  top: 5px;
  left: 5px;
}

.orange-left {
  width: 5px;
  height: 100px;
  top: 5px;
  left: 5px;
}

@keyframes leftgrow {
  0% {
    height: 100px;
    width: 10px;
  }

  40% {
    height: 100%;
    width: 10px;
  }

  100% {
    width: 100%;
    height: 100%;
  }
}

@keyframes leftshrink {
  0% {
    width: 100%;
    height: 100%;
  }

  60% {
    height: 100%;
    width: 10px;
  }

  100% {
    height: 100px;
    width: 10px;
  }
}

@keyframes topgrow {
  0% {
    width: 100px;
    height: 10px;
  }

  60% {
    width: 100%;
    height: 10px;
  }

  100% {
    width: 100%;
    height: 100%;
  }
}

@keyframes topshrink {
  0% {
    width: 100%;
    height: 100%;
  }

  40% {
    width: 100%;
    height: 10px;
  }

  100% {
    width: 100px;
    height: 10px;
  }
}

.orange-left.grow {
  animation: leftgrow .5s forwards;
}

.orange-left.shrink {
  animation: leftshrink .5s forwards;
}

.orange-top.grow {
  animation: topgrow .5s forwards;
}

.orange-top.shrink {
  animation: topshrink .5s forwards;
}

.white-card {
  display: block;
  text-decoration: none;
  position: absolute;
  background-color: white;
  left: 10px;
  top: 10px;
  width: 90%;
  height: calc(100% - 20px);
  padding: 10px;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, .2);
}

.white-card img {
  width: 30px;
  margin-top: 44px;
}

.category {
  font-size: 18px;
  color: #222;
  font-weight: 700;
  margin-top: 1em;
  margin-bottom: 1.5em;
}

.CTA {
  text-decoration: none;
  text-transform: uppercase;
  padding: 8px 20px;
  color: #9f0400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  border: 1px solid #9f0400;
  display: inline-block;
  transition: all .5s;
  font-weight: 600;
}

.CTA.active {
  background-color: #FF8D00;
  color: white;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



p.position_details {
  min-height: 100px;
  font-size: 14px;
  color: #979797;
}

p.position_details b {
  color: #595959;
}

.career_desc_det table {
  border: none;
}

.career_desc_det td {
  border: none;
  font-size: 14px;
  color: #5f5f5f;
}

.career_desc_det th {
  border: none;
  font-size: 14px;
  color: #292929;
  display: flex;
  align-items: center;
}

.career_desc_det ul {
  padding: 0;
}

.careers_apply_form input {
  border: 1px solid #d7d7d7;
  padding: 5px 10px;
  font-size: 17px;
  color: #000;
}

.careers_apply_form textarea {
  border: 1px solid #d7d7d7;
  padding: 5px 5px;
  font-size: 17px;
  color: #000;
}

.careers_apply_form select {
  border: 1px solid #d7d7d7;
  padding: 10px 5px;
  font-size: 15px;
  color: #000;
  background: none;
  outline: none !important;
}

.careers_apply_form input::placeholder {
  font-size: 13px !important;
  color: #535353;
}

.careers_apply_form section::placeholder {
  font-size: 13px !important;
  color: #535353;
}

.careers_apply_form textarea::placeholder {
  font-size: 13px !important;
  color: #535353;
}

.parse_input_statement label {
  align-items: center;
  background: #fff;
  border: 1px dotted #b3b3b3;
  justify-content: center;
  margin-bottom: 1rem;
  padding: 15px 0;
  text-align: center;
  width: 100%;
}

.parse_input_statement label {
  color: #6f6f6f;
  font-size: 13px;
  font-weight: 600;
}

.parse_input_statement svg {
  color: #838383;
  font-size: 30px;
}

.uploaded_file {
  align-items: center;
  background-clip: padding-box;
  border: 1px solid #00000036;
  border-radius: .25rem;
  color: #495057;
  display: flex;
  font-size: 1rem;
  justify-content: space-between;
  line-height: 1.5;
  margin-bottom: 1rem;
  padding: 10px 10px 10px 5px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  width: 100%;
}

.uploaded_file h5 {
  align-items: center;
  color: #000000b5;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

.uploaded_file h5 svg {
  color: #9f0400;
  font-size: 29px;
  margin: 0 5px 0 0;
}

.uploaded_file div {
  display: grid;
}

.uploaded_file div svg {
  cursor: pointer;
  font-size: 16px;
}

svg.dlt_pdf {
  color: #dc3545;
  margin-top: 5px;
}

.career_ap_btn button {
  text-decoration: none;
  text-transform: uppercase;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 13px !important;
  line-height: 16px;
  letter-spacing: 1px;
  border: 1px solid #9f0400;
  display: inline-block;
  transition: all .5s;
  font-weight: 600;
  background: #9f0400;
  margin-top: 1.5em;
}

.career_ap_btn {
  text-align: center;
}

.career_desc_det th svg {
  font-size: 17px;
  color: #9f0400;
  margin-right: 5px;
}

.login-root h1 {
  letter-spacing: -1px;
}

.login-root a {
  color: #9f0400;
  text-decoration: unset;
}

.login-root {
  background: #fff;
  display: flex;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.loginbackground {
  min-height: 692px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
}

.flex-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.center-center {
  align-items: center;
  justify-content: center;
}

.box-root {
  box-sizing: border-box;
}

.flex-direction--column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.box-divider--light-all-2 {
  box-shadow: inset 0 0 0 2px #e3e8ee;
}

.box-background--blue {
  background-color: #9f0400;
}

.box-background--white {
  background-color: #ffffff;
}

.box-background--blue800 {
  background-color: #9f0400;
}

.box-background--gray100 {
  background-color: #e3e8ee;
}

.box-background--cyan200 {
  background-color: #7fd3ed;
}

.padding-top--64 {
  padding-top: 64px;
}

.padding-top--24 {
  padding-top: 24px;
}

.padding-top--48 {
  padding-top: 48px;
}

.padding-bottom--24 {
  padding-bottom: 24px;
}

.padding-horizontal--48 {
  padding: 48px;
}

.padding-bottom--15 {
  padding-bottom: 15px;
}


.flex-justifyContent--center {
  -ms-flex-pack: center;
  justify-content: center;
}

.formbg {
  margin: 0px auto;
  width: 100%;
  max-width: 448px;
  background: white;
  border-radius: 4px;
  box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}

.login-root span {
  display: block;
  font-size: 20px;
  line-height: 28px;
  color: #1a1f36;
}

.login-root label {
  margin-bottom: 10px;
}

.reset-pass a,
label {
  font-size: 14px;
  font-weight: 600;
  display: block;
}

.reset-pass>a {
  text-align: right;
  margin-bottom: 10px;
}

.grid--50-50 {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}

.login-root .field input {
  font-size: 16px;
  line-height: 28px;
  padding: 8px 16px;
  width: 100%;
  min-height: 44px;
  border: unset;
  border-radius: 4px;
  outline-color: rgb(84 105 212 / 0.5);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}

.login-root input[type="submit"] {
  background-color: #9f0400;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px,
    #9f0400,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.login-root .field-checkbox input {
  width: 20px;
  height: 15px;
  margin-right: 5px;
  box-shadow: unset;
  min-height: unset;
}

.login-root .field-checkbox label {
  display: flex;
  align-items: center;
  margin: 0;
}

a.ssolink {
  display: block;
  text-align: center;
  font-weight: 600;
}

.footer-link span {
  font-size: 14px;
  text-align: center;
}

.listing a {
  color: #697386;
  font-weight: 600;
  margin: 0 10px;
}

.animationRightLeft {
  animation: animationRightLeft 2s ease-in-out infinite;
}

.animationLeftRight {
  animation: animationLeftRight 2s ease-in-out infinite;
}

.tans3s {
  animation: animationLeftRight 3s ease-in-out infinite;
}

.tans4s {
  animation: animationLeftRight 4s ease-in-out infinite;
}

@keyframes animationLeftRight {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(1000px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes animationRightLeft {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(-1000px);
  }

  100% {
    transform: translateX(0px);
  }
}


.login-root {
  background: #fff;
  display: flex;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.loginbackground {
  min-height: 692px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
}

.login-root .flex-flex {
  display: flex;
}

.login-root .align-center {
  align-items: center;
}

.login-root .center-center {
  align-items: center;
  justify-content: center;
}

.login-root .box-root {
  box-sizing: border-box;
}

.login-root .flex-direction--column {
  -ms-flex-direction: column;
  flex-direction: column;
}

.loginbackground-gridContainer {
  display: grid;
  grid-template-columns: [start] 1fr [left-gutter] repeat(16, 86.6px) [left-gutter] 1fr [end];
  grid-template-rows: [top] 1fr [top-gutter] repeat(8, 64px) [bottom-gutter] 1fr [bottom];
  justify-content: center;
  margin: 0 -2%;
  transform: rotate(-12deg) skew(-12deg);
}

.login-root .box-divider--light-all-2 {
  box-shadow: inset 0 0 0 2px #e3e8ee;
}

.box-background--blue {
  background-color: #9f0400;
}

.box-background--white {
  background-color: #ffffff;
}

.box-background--blue800 {
  background-color: #9f0400;
}

.box-background--gray100 {
  background-color: #e3e8ee;
}

.box-background--cyan200 {
  background-color: #9f0400;
}

.padding-top--64 {
  padding-top: 64px;
}

.padding-top--24 {
  padding-top: 24px;
}

.padding-top--48 {
  padding-top: 48px;
}

.padding-bottom--24 {
  padding-bottom: 24px;
}

.padding-horizontal--48 {
  padding: 48px;
}

.padding-bottom--15 {
  padding-bottom: 15px;
}


.flex-justifyContent--center {
  -ms-flex-pack: center;
  justify-content: center;
}

.formbg {
  margin: 0px auto;
  width: 100%;
  max-width: 448px;
  background: white;
  border-radius: 4px;
  box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}

.login-root span {
  display: block;
  font-size: 20px;
  line-height: 28px;
  color: #1a1f36;
}

.login-root label {
  margin-bottom: 10px;
}

.reset-pass a,
label {
  font-size: 14px;
  font-weight: 600;
  display: block;
  margin-bottom: 5px;
}

.reset-pass>a {
  text-align: right;
  margin-bottom: 10px;
}

.grid--50-50 {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}

.field input {
  font-size: 16px;
  line-height: 28px;
  padding: 8px 16px;
  width: 100%;
  min-height: 44px;
  border: unset;
  border-radius: 4px;
  outline-color: #9f0400;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}

.login-root input[type="submit"] {
  background-color: #9f0400;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, #9f0400 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}

.login-root .field-checkbox input {
  width: 20px;
  height: 15px;
  margin-right: 5px;
  box-shadow: unset;
  min-height: unset;
}

.field-checkbox label {
  display: flex;
  align-items: center;
  margin: 0;
}

a.ssolink {
  display: block;
  text-align: center;
  font-weight: 600;
}

.footer-link span {
  font-size: 14px;
  text-align: center;
}

.listing a {
  color: #697386;
  font-weight: 600;
  margin: 0 10px;
}

.animationRightLeft {
  animation: animationRightLeft 2s ease-in-out infinite;
}

.animationLeftRight {
  animation: animationLeftRight 2s ease-in-out infinite;
}

.tans3s {
  animation: animationLeftRight 3s ease-in-out infinite;
}

.tans4s {
  animation: animationLeftRight 4s ease-in-out infinite;
}

@keyframes animationLeftRight {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(1000px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes animationRightLeft {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(-1000px);
  }

  100% {
    transform: translateX(0px);
  }
}

.dash_menu {
  width: -moz-fit-content;
  width: 20%;
  background: #ffffff;
  transition: width 0.3s ease-out;
}

.dash_brand img {
  min-height: 70px;
  max-height: 70px;
  object-fit: contain;
  width: 100%;
  padding: 5px 0;
}

.dash_menus ul {
  padding: 0;
  margin: 0;
}

.dash_menus ul li {
  display: flex;
  align-items: center;
  padding: 10px 20px !important;
  width: 100%;
  cursor: pointer;
}

.dash_menus li .menu_name {
  font-size: 15px;
  color: #181818;
  margin-left: 8px;
}


.dash_menus li svg {
  font-size: 31px;
  color: #292b2c;
  background: #fff;
  box-shadow: 0px 0px 5px 0px #00000052;
  border-radius: 5px;
  width: 35px;
  height: 35px;
  padding: 9px;
}

.dash_menus ul li:hover svg {
  color: #000;
}

.dash_menus ul li:hover {
  color: #000;
}

.dash_menus ul li:hover li.active-nav::before {
  background: #fff;
}

.dash_menus {
  padding: 1em 0;
}

.dashboar_main {
  display: flex;
}

.dash_content_section {
  background: #e9e9e9;
  width: 100%;
  display: block;
}

.dash_bredcrumb {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #00000038;
  padding: 5px 10px;
  background: #fff;
  margin-bottom: 0.5em;
}

ol {
  margin: 0 !important;
  padding: 0 !important;
}

.dash_bredcrumb h2 {
  font-size: 16px;
  margin: 0;
  letter-spacing: 0.5px;
  font-weight: 600;
  color: #181818;
}

ol.breadcrumb li a {
  font-size: 14px;
  color: #9f0400;
  font-family: roboto, sans-serif;
  font-weight: 500;
}

ol.breadcrumb li {
  font-size: 15px;
  color: #9f0400;
  font-family: roboto, sans-serif;
  font-weight: 500;
}

.dash_header_sec {
  background: #ffffff;
  padding: 10px;
  box-shadow: 8px 0px 13px 0px #00000061;
  display: flex;
  align-items: center;
  justify-content: space-between;
}



main.main_comp_sec {
  padding: 1rem;
}

.admin_humber svg {
  font-size: 31px;
  cursor: pointer;
  color: #9f0400;
}

.dash_menus ul li:hover {
  background: #333333;
}


.dash_menus ul li:hover .menu_name {
  color: #fff;
}

/* Apply custom scrollbar styles */
.custom-scrollbar {
  height: 100vh;
  overflow-y: scroll;
}

/* WebKit-based browsers */
.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
  background: #9f0400;
  /* Width of the scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #9f0400;
  /* Color of the scrollbar track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #9f0400;
  /* Color of the scrollbar handle */
  border-radius: 10px;
  /* Roundness of the scrollbar handle */
  border: 3px solid #9f0400
    /* Padding around the scrollbar handle */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #9f0400;
  /* Color when hovering over the scrollbar handle */
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #cfcfcf #f1f1f1;
}

.close_dash_menu {
  width: 5%;
  transition: width 0.3s ease-out;
}

.open_full {
  width: 100%;
  transition: width 0.3s ease-out;
}

.dash_grid {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 10px 0 #0000000d;
  margin-bottom: 12px;
  padding: 20px;
  margin: 0px;
}

.card__text {
  margin-bottom: 2rem !important;
}

.ser_des_content h4 {
  font-size: 19px;
  color: #393939;
  font-weight: 600;
}

.ser_des_content p {
  color: #5b5b5b;
}

.flat-service-details .post-inner p {
  margin-bottom: 10px;
  text-align: justify;
}

.flat-service-details .post-inner p {
  margin-bottom: 10px;
  text-align: justify;
}

li.d-g p {
  margin: 0 !important;
}

.flat-about-2 .about-2-content-left .icon-tick::after,
.flat-service-details .post-inner .icon-tick::after,
.flat-portfolio-details .icon-tick::after {
  font-size: 15px !important;
}

li.d-g p {
  color: #5b5b5b;
}

.flat-service-details .post-inner .work-process h4,
.flat-portfolio-details .work-process h4 {
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
}

.flat-about-2 .about-2-content-left .icon-tick,
.flat-service-details .post-inner .icon-tick {
  box-shadow: 0 5px 12px #77737347 !important;
}

.flat-service-details .post-inner .work-process .icon-tick,
.flat-portfolio-details .icon-tick {
  display: inline-block;
  width: 28px;
  height: 28px;
  line-height: 28px;
  margin-right: 8px;
  background-color: #ffffff !important;
  flex-shrink: 0;
}

.flat-service-details .post-inner .work-process .icon-tick::after,
.flat-portfolio-details .icon-tick:after {
  color: #9f0400 !important;
}

.des_icon_box {
  display: grid !important;
  justify-content: center;
  margin: 0 !important;
  text-align: center !important;
  align-items: start !important;
}

.service_details_icon_card {
  padding: 2em 0.5em;
}

.service_details_icon_card p {
  font-size: 15px;
  text-align: center !important;
  line-height: 21px;
  min-height: 60px;
}

.service_details_icon_card h4 {
  font-family: roboto, sans-serif;
  font-size: 17px !important;
  margin: 10px 0;
  color: #000000;
  text-transform: capitalize !important;
}

.service_details_icon_card svg {
  font-size: 40px;
  color: #0396c1;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #9f0400;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card1 {
  display: block;
  position: relative;
  background-color: #f7f7f7;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 0.5em 0;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.card1:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #9f04004d;
  height: 32px;
  width: 47px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
  cursor: pointer;
}

.card1:hover:before {
  transform: scale(21);
}

.card1:hover p {
  transition: all 0.3s ease-out;
  color: #000000;
}

.card1:hover h3 {
  transition: all 0.3s ease-out;
  color: #9f0400;
}

.card2 {
  display: block;
  top: 0px;
  position: relative;
  max-width: 262px;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
  border: 1px solid #f2f8f9;
}

.card2:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0px 4px 8px rgba(38, 38, 38, 0.2);
  top: -4px;
  border: 1px solid #cccccc;
  background-color: white;
}

.card2:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #00838d;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(2);
  transform-origin: 50% 50%;
  transition: transform 0.15s ease-out;
}

.card2:hover:before {
  transform: scale(2.15);
}

.card3 {
  display: block;
  top: 0px;
  position: relative;
  max-width: 262px;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  overflow: hidden;
  border: 1px solid #f2f8f9;
}

.card3 .go-corner {
  opacity: 0.7;
}

.card3:hover {
  border: 1px solid #00838d;
  box-shadow: 0px 0px 999px 999px rgba(255, 255, 255, 0.5);
  z-index: 500;
}

.card3:hover p {
  color: #00838d;
}

.card3:hover .go-corner {
  transition: opactiy 0.3s linear;
  opacity: 1;
}

.card4 {
  display: block;
  top: 0px;
  position: relative;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 32px 24px;
  text-decoration: none;
  overflow: hidden;
  border: 2px dotted #cccccc;
  transition: all 0.2s ease-out;
  margin-bottom: 2rem;
  cursor: pointer;
}

.card4 .go-corner {
  background-color: #9f0400;
  height: 100%;
  width: 16px;
  padding-right: 9px;
  border-radius: 0;
  transform: skew(6deg);
  margin-right: -36px;
  align-items: start;
  background-image: linear-gradient(-45deg, #9f0400 1%, #9f0400 100%);
  transition: all 0.2s ease-out;
}

.card4 .go-arrow {
  transform: skew(-6deg);
  margin-left: -2px;
  margin-top: 9px;
  opacity: 0;
  transition: all 0.2s ease-out;
}

.card4:hover {
  border: 2px dotted #ffffff;
  transition: all 0.2s ease-out;
  background: #9f0400;
}

.card4 h3 {
  margin-top: 8px;
}

.card4:hover .go-corner {
  margin-right: -12px;
}

.card4:hover .go-arrow {
  opacity: 1;
}

.card1 h3 {
  font-size: 18px;
}

.card1 p {
  font-size: 15px;
  text-align: left !important;
  line-height: 26px;
  margin-top: 1em;
  color: dimgrey;
}


.mtb-3 {
  margin: 25px 0 !important;
}


.card4 svg {
  font-size: 32px;
  color: #ffffff;
  border: 1px solid;
  width: 50px;
  height: 50px;
  padding: 1rem;
  background: #9f0400;
}

.card4 h3 {
  margin-top: 1em;
  font-size: 17px;
}

.card4 p {
  font-size: 15px;
  text-align: left !important;
  color: #757575;
}

.card4:hover h3 {
  color: white;
}

.card4:hover p {
  color: white;
}

.ser_det_sec .post-inner {
  margin-bottom: 3em;
}

.services_det_add_sec {
  justify-content: space-between;
}

.flat-team .team-content-box .team-post {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

div#gallery {
  padding: 8em 0;
}


.p-r {
  position: relative;
}

.gallery_model {
  position: fixed !important;
  z-index: 99999;
  background: #000000bd;
  width: 100%;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
}

.model_bg_img {
  width: 80%;
  height: 80vh;
  margin: auto;
  border: 5px solid #fff;
}

button.model_close {
  position: absolute;
  right: 1em;
  top: 1em;
  background: white;
  color: #000;
  padding: 5px 20px;
}

.education {
  --bg-color: #ffd861;
  --bg-color-light: #ffeeba;
  --text-color-hover: #4C5656;
  --box-shadow-color: rgba(255, 215, 97, 0.48);
}

.credentialing {
  --bg-color: #B8F9D3;
  --bg-color-light: #e2fced;
  --text-color-hover: #4C5656;
  --box-shadow-color: rgba(184, 249, 211, 0.48);
}

.wallet {
  --bg-color: #CEB2FC;
  --bg-color-light: #F0E7FF;
  --text-color-hover: #fff;
  --box-shadow-color: rgba(206, 178, 252, 0.48);
}

.human-resources {
  --bg-color: #DCE9FF;
  --bg-color-light: #f1f7ff;
  --text-color-hover: #4C5656;
  --box-shadow-color: rgba(220, 233, 255, 0.48);
}

.hover-card {
  width: 80%;
  background: #fff;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 14px 26px rgb(0 0 0 / 11%);
  transition: all 0.3s ease-out;
  text-decoration: none;
  min-height: 350px;
  text-align: center;
  padding: 0 2.5em;
  border-radius: 10px;
  margin: auto;
  margin-bottom: 4em;
}

.hover-card:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgb(0 0 0 / 6%), 0 24px 46px #9f04004d;
}

.hover-card:hover .overlay {
  transform: scale(4) translateZ(0);
  width: 118px;
  position: absolute;
  height: 118px;
  border-radius: 50%;
  background: #9f04001a;
  top: 70px;
  left: 50px;
  z-index: 0;
  transition: transform 0.3s ease-out;
  z-index: -1;
}

.hover-card:hover .circle {
  border-color: #9f0400;
  background: #9f0400;
}

.hover-card:hover .circle:after {
  background: #ffffff;
}

.hover-card:hover p {
  color: #9f0400;
}

.hover-card:active {
  transform: scale(1) translateZ(0);
  box-shadow: 0 15px 24px rgba(0, 0, 0, 0.11),
    0 15px 24px var(--box-shadow-color);
}

.hover-card p {
  font-size: 18px;
  color: #4d4d4d;
  margin-top: 10px;
  z-index: 1000;
  transition: color 0.3s ease-out;
  font-weight: 600;
}

.hover-card .circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #ffe2e1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
}

.hover-card .circle:after {
  content: "";
  width: 85px;
  height: 85px;
  display: block;
  position: absolute;
  background: #ffffff;
  border-radius: 50%;
  top: 7px;
  left: 7px;
  transition: opacity 0.3s ease-out;
}

.hover-card .circle svg {
  z-index: 10000;
  transform: translateZ(0);
}

.ptb-3 {
  padding: 3em 0;
}

.hover-card span {
  font-size: 15px;
  color: #797979;
}

.hover-card svg {
  font-size: 42px;
  color: #9f0400;
}

.t-c p {
  text-align: center !important;
}

.t-c {
  text-align: center !important;
}

h3.section-heading {
  border: none;
}

.flat-about-2 .ptb-3 {
  padding-bottom: 0;
}

h3.section-heading.abt_hed {
  padding: 0;
  margin-bottom: 0.1em !important;
  font-weight: 600;
}

.des_list_td h4 {
  font-size: 19px !important;
  color: #9f0400;
  margin-bottom: 1em;
}

li.services_list_li p {
  font-family: roboto, sans-serif;
  line-height: 21px;
  font-size: 14px;
  color: #5f5f5f;
  text-align: left !important;
  margin-left: 10px;
  text-transform: capitalize;
  margin-bottom: 0 !important;
}

li.services_list_li svg {
  font-size: 18px;
  color: #9f040091;
}

.des_list_td {
  text-align: left;
}

.list_ser_sec {
  padding: 2em;
}

li.services_list_li {
  display: flex;
  align-items: center;
  margin-right: 30px;
  margin-bottom: 10px;
}

.nxt_pr_arrow {
  position: absolute;
  bottom: 1em;
  display: flex;
  justify-content: center;
  width: 100%;
}

.nxt_pr_arrow button {
  background: #ffffff;
  color: #b11423;
  margin: 0 1em;
  padding: 5px 20px;
  border-radius: 5px;
  box-shadow: 0px 0 22px -1px #ffffff8a;
}

ul.menu_closed li svg {
  font-size: 24px;
}

.close_dash_menu {
  width: min-content !important;
}

.dash_menus ul li {
  padding-right: 1em !important;
}

li.active-nav::before {
  content: '';
  position: absolute;
  width: 5px;
  height: 30px;
  left: 1px;
  z-index: 9999999;
  background: #9f0400;
  border-radius: 35px;
}

li.active-nav {
  position: relative;
}

li.active-nav svg {
  color: #9f0400;
}

li.active-nav .menu_name {
  color: #9f0400;
}

img.icnon_logo {
  min-height: 50px !important;
  max-height: 50px !important;
  padding: 0px;
}

.login_btn button {
  background: #fff;
  color: #000;
  padding: 5px 13px;
  box-shadow: 0px 0px 7px 0px #00000052;
  border-radius: 5px;
  font-size: 15px;
}

.icon_btn.login_btn svg {
  color: #9f0400;
  font-size: 23px;
}

.login-btn button {
  background: #fff;
  color: #000;
  padding: 10px 30px;
  box-shadow: 0px 0px 7px 0px #00000052;
  border-radius: 5px;
  font-size: 15px;
  margin-top: 10px;
}

.al-c {
  text-align: center;
}

.dash_bredcrumb svg {
  font-size: 16px;
}

.dash_brand {
  /* background: #fff; */
  margin: 1.5em;
  padding: 0 0px;
  border-radius: 5px;
  /* box-shadow: 3px 8px 15px 0px #6f6f6f61; */
  margin-bottom: 0;
}

.profile-nav,
.profile-info {
  margin-top: 30px;
}

.profile-nav .user-heading {
  background: #ffffff;
  color: #fff;
  border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  padding: 30px;
  text-align: center;
}

.dash_profile_img img {
  width: 150px;
  min-height: 150px;
  max-height: 150px;
  object-fit: contain;
  background: #c9c9c9;
  padding: 7px;
  border-radius: 50%;
}

.profile-nav .user-heading.round a {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  border: 10px solid rgba(255, 255, 255, 0.3);
  display: inline-block;
}

.profile-nav .user-heading a img {
  width: 112px;
  height: 112px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
}

.profile-nav .user-heading h1 {
  font-weight: 700;
  color: #292b2c;
  font-size: 17px;
  margin-top: 1em;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.profile-nav .user-heading p {
  font-size: 11px;
  color: #6f6f6f;
  font-weight: unset;
  letter-spacing: 1px;
}

.profile-nav ul {
  margin-top: 1px;
  padding: 0;
}

.profile-nav ul>li:last-child {
  border-bottom: none;
}

.profile-nav ul>li>a {
  color: #000000;
  padding-left: 5px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
}


.profile-nav ul>li:last-child>a:last-child {
  border-radius: 0 0 4px 4px;
  -webkit-border-radius: 0 0 4px 4px;
}

.profile-nav ul>li>a i {
  font-size: 16px;
  color: #000000 !important;
  padding-right: 5px;
}

.profile-nav ul>li {
  border-bottom: 1px solid #ebeae6;
  margin-top: 15px;
}

.r-activity {
  margin: 6px 0 0;
  font-size: 12px;
}


.p-text-area,
.p-text-area:focus {
  border: none;
  font-weight: 300;
  box-shadow: none;
  color: #c3c3c3;
  font-size: 16px;
}

.profile-info .panel-footer {
  background-color: #f8f7f5;
  border-top: 1px solid #e7ebee;
}

.profile-info .panel-footer ul li a {
  color: #7a7a7a;
}

.bio-graph-heading {
  background: #fbc02d;
  color: #fff;
  text-align: center;
  font-style: italic;
  padding: 40px 110px;
  border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  font-size: 16px;
  font-weight: 300;
}

.bio-graph-info {
  color: #89817e;
}


.bio-graph-info h1 {
  font-size: 17px;
  font-weight: 500;
  color: #292b2c;
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
}

.heading_profile h1 {
  padding: 0;
  margin: 0;
}

.heading_profile {
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
}

.heading_profile button {
  background: #fff;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 15px;
  display: flex;
  align-items: center;
  font-weight: 500;
  border: 1px solid #9f04002b;
}

.bb {
  border-bottom: 2px dotted #292b2c29;
}

.bio-row {
  width: 50%;
  float: left;
  margin-bottom: 10px;
  padding: 0 15px;
}

.bio-row p span {
  width: 100px;
  display: inline-block;
  color: #292b2c;
  font-size: 14px;
  font-weight: 500;
}

.bio-row p {
  font-size: 15px;
  color: #0000008a;
}

.bio-chart,
.bio-desk {
  float: left;
}

.bio-chart {
  width: 40%;
}

.bio-desk {
  width: 60%;
}

.bio-desk h4 {
  font-size: 15px;
  font-weight: 400;
}

.bio-desk h4.terques {
  color: #4CC5CD;
}

.bio-desk h4.red {
  color: #e26b7f;
}

.bio-desk h4.green {
  color: #97be4b;
}

.bio-desk h4.purple {
  color: #caa3da;
}

.file-pos {
  margin: 6px 0 10px 0;
}

.profile-activity h5 {
  font-weight: 300;
  margin-top: 0;
  color: #c3c3c3;
}

.summary-head {
  background: #ee7272;
  color: #fff;
  text-align: center;
  border-bottom: 1px solid #ee7272;
}

.summary-head h4 {
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.summary-head p {
  color: rgba(255, 255, 255, 0.6);
}

ul.summary-list {
  display: inline-block;
  padding-left: 0;
  width: 100%;
  margin-bottom: 0;
}

ul.summary-list>li {
  display: inline-block;
  width: 19.5%;
  text-align: center;
}

ul.summary-list>li>a>i {
  display: block;
  font-size: 18px;
  padding-bottom: 5px;
}

ul.summary-list>li>a {
  padding: 10px 0;
  display: inline-block;
  color: #818181;
}

ul.summary-list>li {
  border-right: 1px solid #eaeaea;
}

ul.summary-list>li:last-child {
  border-right: none;
}

.activity {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}

.activity.alt {
  width: 100%;
  float: right;
  margin-bottom: 10px;
}

.activity span {
  float: left;
}

.activity.alt span {
  float: right;
}

.activity span,
.activity.alt span {
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  background: #eee;
  text-align: center;
  color: #fff;
  font-size: 16px;
}

.activity.terques span {
  background: #8dd7d6;
}

.activity.terques h4 {
  color: #8dd7d6;
}

.activity.purple span {
  background: #b984dc;
}

.activity.purple h4 {
  color: #b984dc;
}

.activity.blue span {
  background: #90b4e6;
}

.activity.blue h4 {
  color: #90b4e6;
}

.activity.green span {
  background: #aec785;
}

.activity.green h4 {
  color: #aec785;
}

.activity h4 {
  margin-top: 0;
  font-size: 16px;
}

.activity p {
  margin-bottom: 0;
  font-size: 13px;
}

.activity .activity-desk i,
.activity.alt .activity-desk i {
  float: left;
  font-size: 18px;
  margin-right: 10px;
  color: #bebebe;
}

.activity .activity-desk {
  margin-left: 70px;
  position: relative;
}

.activity.alt .activity-desk {
  margin-right: 70px;
  position: relative;
}

.activity.alt .activity-desk .panel {
  float: right;
  position: relative;
}

.activity-desk .panel {
  background: #F4F4F4;
  display: inline-block;
}


.activity .activity-desk .arrow {
  border-right: 8px solid #F4F4F4 !important;
}

.activity .activity-desk .arrow {
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  display: block;
  height: 0;
  left: -7px;
  position: absolute;
  top: 13px;
  width: 0;
}

.activity-desk .arrow-alt {
  border-left: 8px solid #F4F4F4 !important;
}

.activity-desk .arrow-alt {
  border-bottom: 8px solid transparent;
  border-top: 8px solid transparent;
  display: block;
  height: 0;
  right: -7px;
  position: absolute;
  top: 13px;
  width: 0;
}

.activity-desk .album {
  display: inline-block;
  margin-top: 10px;
}

.activity-desk .album a {
  margin-right: 10px;
}

.activity-desk .album a:last-child {
  margin-right: 0px;
}

.dash_menus {
  padding: 1em 0;
  max-height: 100vh;
  overflow-x: hidden !important;
  overflow-y: scroll;
  padding-bottom: 5em !important;
}

img.main_logo_dash {
  padding: 1.5em;
  width: 230px;
}

.heading_profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heading_profile button svg {
  color: #9f0400;
  margin-right: 5px;
}

button {
  padding: initial;
  background: no-repeat;
  color: #000;
}

.dash-content-grid {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 10px 0 #0000000d;
  margin-bottom: 12px;
  padding: 20px;
}

.attendance_section_head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
}

.attentance_field_dateMonth {
  margin-right: 1rem;
}

.attendance_section_head select {
  border: 1px solid #9b9b9b54;
  background: #fff;
  width: 250px;
  padding: 10px 7px;
  border-radius: 5px;
}

.attendance_icon_note {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

.attendance_icon_note ul li {
  color: #434343;
  display: inline;
  display: initial;
  font-size: 14px;
  letter-spacing: .5px;
  margin-right: 1rem;
}

.attendance_present_icon {
  color: green;
}

.attendance_leave_icon {
  color: red;
}

.attendance_holiday_icon {
  color: #0d6efd;
}

.attendance_list_sec thead {
  background: #00000017;
}

.attendance_user_profile {
  align-items: center;
  background: #fff;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  padding: 6px;
  width: -webkit-max-content;
  width: max-content;
}

.attendance_user_profile img {
  border-radius: 100%;
  height: 40px;
  margin: 0 10px 0 0;
  width: 40px;
}

.attendance_user_profile h4 {
  font-size: 13px;
  letter-spacing: .2px;
  margin: 5px 0 0;
  text-transform: uppercase;
}

button.att_btn_eml {
  background: no-repeat;
  border: none;
  margin: 0;
  padding: 0;
}

.attendance_holiday {
  position: relative;
}

.attendance_holiday_icon {
  color: #0d6efd;
}

.attendance_holiday_details {
  background-color: grey;
  border-radius: 4px;
  bottom: 26px;
  color: #fff;
  display: none;
  font-size: 14px;
  font-weight: 400;
  padding: 4px 10px;
  position: absolute;
  width: -webkit-max-content;
  width: max-content;
}

.attendance_holiday:hover .attendance_holiday_details {
  display: block !important;
}

.attendance_present_icon {
  color: green;
}

.attendance_list_sec td svg {
  font-size: 13px;
}

.attendance_leave_icon {
  color: red;
}

.atten_profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #9f04001f;
  border-bottom: none;
  background: #0000000d;
}

.atten_profile img {
  width: 70px;
  min-height: 70px;
  max-height: 70px;
  object-fit: cover;
  border-radius: 50%;
  padding: 1rem;
}

.atten_profile h6 {
  margin: 0;
  font-size: 15px;
  letter-spacing: 1px;
  margin-left: 1rem;
}

select {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none !important;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box;
}

.select:focus {
  border: 2px solid #000;
  /* Customize the border style */
  outline: none;
  /* Remove the outline if you want */
}

.flex-alc {
  display: flex;
  align-items: center;
}

.menu_drop img {
  width: 35px;
  min-height: 35px;
  max-height: 35px;
  object-fit: cover;
  border-radius: 50%;
}

.menu_drop {
  background: none !important;
  border: none !important;
  margin-left: 1.5em;
}

ul {
  padding: 0;
}

form.chckIn_form {
  padding: 2em;
  background: #f1f1f1;
  border-radius: 5px;
}

form.chckIn_form input {
  padding: 10px 8px;
  font-size: 15px;
  text-transform: capitalize;
}

modal-content button {
  font-size: 15px !important;
}

form.chckIn_form .icon_btn.login_btn {
  margin-top: 1.5em;
  text-align: center !important;
}

form.chckIn_form button {
  padding: 7px 30px;
}

.leave_and_blnz_cont {
  border-bottom: 1px solid #bbbbbb7a;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.sub_leave_inputs {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dash-fm-label {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: .3px;
}

.dateFontsize14 {
  font-size: 14px;
}

.dash-fm-input {
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  padding: 8px 10px !important;
  width: -webkit-fill-available;
  width: -moz-available;
  font-size: 14px !important;
}

.tableTopHeaderSec {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 0 8px;
}

.EntriesPerPage,
.table-pagination-left-right {
  background-color: #fff;
  color: rgba(73, 80, 87, .75);
  display: block;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  width: auto;
}

.EntriesPerPage {
  background-clip: padding-box;
  border: 1px solid #eaedf1;
  border-radius: 3px;
  font-size: 15px;
  font-weight: 400;
  padding: 7px 12px;
}

.createUser_sec {
  display: flex;
}

.dash_action_btn {
  align-items: center;
  background: none;
  border: 1px solid #9f040038;
  border-radius: 3px;
  display: flex;
  font-size: 13px;
  justify-content: space-around;
  margin-left: .2rem;
  padding: 8px 15px;
  text-transform: capitalize;
}

button.dash_action_btn.apply_leave_btn {
  width: 150px;
}

input {
  color: #565872;
  border: 1px solid #cdcdcd !important;
  padding: 8px 23px !important;
  letter-spacing: .4px;
  line-height: inherit;
  width: 100%;
  height: auto;
  text-shadow: none;
  box-shadow: none;
  box-sizing: border-box;
  transition: border ease .238s;
  outline: none !important;
  font-size: 14px;
}

.search_leave {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-between;
}

td {
  font-size: 14px;
}

th {
  font-size: 15px;
}

ul.table-pagination {
  display: flex;
  align-items: center;
  margin: 0;
}

button.table-pagination-left-right {
  background: #9f0400;
  padding: 4px 10px;
  color: #fff;
}

ul.table-pagination li button {
  background: #9f040042;
  padding: 4px 10px;
  color: #fff;
  border-right: 1px solid;
}

/* button.active {
  background: #9f0400 !important;
} */
ul.table-pagination li button.active {
  background: #333333;
}

.modal-footer button {
  background: #198754;
  color: #fff;
  padding: 5px 13px;
  border-radius: 5px;
  font-size: 15px;
  border: none;
}

.dash-fm-group {
  margin: 5px 0;
}

.modal-body {
  padding: 2rem !important;
}

select.dash-fm-input {
  font-size: 14px;
  padding: 10px 5px !important;
  text-transform: capitalize;
}
.attentance_field_dateMonth {
  margin-right: 1rem;
}